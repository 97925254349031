export class AddAppointmentScreen {
    subject = {
        required: 'Subject is required.',
    }
    dateofAppointment = {
        required: 'Date of Appointment is required.',
        invalidtDate: 'Date of Appointment should be less than To date',
    }

    startTime = {
        required: 'Start Time is required.',
    }
    endTime = {
        required: 'End Time is required.',
        maxTime: 'End Time should be greater than Start Time.'
    }
    companyName = {
        required: 'Prospect Name is required.',
    }
    typeOfVisit = {
        required: 'Type Of Visit is required.',
    }
    contactId = {
        required: 'Contact Name is required.',
    }
    contactNumber = {
        mask: 'Invalid Contact Number number.',
        required: 'Contact Number is required.',
    }

    note = {
        maxlength: 'Notes should not exceed more than 500 characters.'
    }

    opportunitySize = {
        required: 'Opportunity Size is required.',
    }
    typeofEventName = {
        required: 'Event is required.',
    }

}


