<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Add Appointment</h2>
                </div>

                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="row" *ngIf="showError">
                <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                        <ul class="view-error">
                            <li *ngFor="let err of errorList">{{err}}</li>
                        </ul>
                        <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3" method="post" [formGroup]="addappointmentForm">
                        <div class="add-form">
                            <div class="add-fld">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12">
                                        <div class="add-title">
                                            <h3>Appointment Details</h3>
                                        </div>
                                        <div class="row">
                                            <div class="row frmrow">

                                                <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                    <label class="form-label">Event
                                                        <span>*</span>
                                                    </label>

                                                    <select (change)="typeOfEvent($event)" class="select-cs form-select"
                                                        name="event" formControlName="typeofEventName">
                                                        <option disabled value="">Select Event</option>
                                                        <!-- <option value="Job Fair" [selected]="SelectedJobFairvalue">Job
                                                            Fair
                                                        </option> -->
                                                        <option value="Event / Conference"
                                                            [selected]="SelectedEventConferencevalue">
                                                            Event / Conference
                                                        </option>
                                                        <!-- <option value="Company Event"
                                                            [selected]="SelectedCompanyEventvalue">Company
                                                            Event
                                                        </option> -->
                                                        <option value="Prospect Meeting"
                                                            [selected]="SelectedProspectMeetingvalue">
                                                            Prospect Meeting
                                                        </option>
                                                        <option value="Client Meeting"
                                                            [selected]="SelectedClientMeetingvalue">Client Meeting
                                                        </option>
                                                    </select>

                                                    <div class="display-error"
                                                        *ngIf="f.typeofEventName.invalid && submitted">
                                                        <span *ngIf="f.typeofEventName.errors?.required">
                                                            {{ _AddAppointmentScreen.typeofEventName.required }}
                                                        </span>
                                                    </div>

                                                </div>

                                                <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                    <label class="form-label">Subject
                                                        <span>*</span></label>
                                                    <input formControlName="subject" class="form-control"
                                                        placeholder="Subject" dataTextFocus />
                                                    <div class="display-error" *ngIf="f.subject.invalid && submitted">
                                                        <span *ngIf="f.subject.errors?.required">
                                                            {{ _AddAppointmentScreen.subject.required }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-12 mb-3" *ngIf="disableJobFairFlag">
                                                    <label class="form-label">Company Name
                                                        <span *ngIf="isProspectRequired">*</span></label>
                                                    <div class="flex-div">
                                                        <p-autoComplete placeholder="Prospect Name"
                                                            class="form-control auto-comp" [showEmptyMessage]="true"
                                                            [suggestions]="accountList"
                                                            (completeMethod)="getCompanyList('companyName', $event)"
                                                            field="prospectName" [minLength]="1"
                                                            (onSelect)="selectItem($event.value, 'companyName')"
                                                            (onDropdownClick)="getCompanyList('companyName', $event)"
                                                            (onClear)="lookUpDataFilterChanges($event)"
                                                            formControlName="companyName"
                                                            [ngClass]="{error:f.companyName.invalid && submitted}">
                                                            <ng-template let-company pTemplate="item">
                                                                <div class="company-item">
                                                                    <div>{{ company.prospectName }}</div>
                                                                </div>
                                                            </ng-template>
                                                        </p-autoComplete>
                                                        <img (click)="openLookupModal('companyName')"
                                                            src="assets/images/search.svg" alt="" class="ms-2" />
                                                    </div>
                                                    <div class="display-error"
                                                        *ngIf="f.companyName.invalid && submitted">
                                                        <span *ngIf="f.companyName.errors?.required">
                                                            {{ _AddAppointmentScreen.companyName.required }}
                                                        </span>
                                                    </div>

                                                </div>


                                                <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                    <label class="form-label">In Person or Virtual
                                                        <span>*</span></label>

                                                    <select class="select-cs form-select" name="size"
                                                        formControlName="typeOfVisit">
                                                        <option disabled value="">Select Visit</option>
                                                        <option value="In person">In Person
                                                        </option>
                                                        <option value="Virtual">Virtual
                                                        </option>
                                                    </select>
                                                    <div class="display-error"
                                                        *ngIf="f.typeOfVisit.invalid && submitted">
                                                        <span *ngIf="f.typeOfVisit.errors?.required">
                                                            {{ _AddAppointmentScreen.typeOfVisit.required }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-3" *ngIf="hideContactField">
                                                    <label class="form-label">Contact Name
                                                        <span *ngIf="isProspectRequired">*</span></label>
                                                    <select class="select-cs form-select" name="size"
                                                        (ngModelChange)="getContactName($event)"
                                                        formControlName="contactId">
                                                        <option disabled value="">Select Contact</option>
                                                        <option *ngFor="let contact of contactList" [ngValue]="contact">
                                                            {{ contact.firstName }} {{ contact.lastName }}
                                                        </option>
                                                        <option value="0">No Contact</option>
                                                    </select>
                                                    <div class="display-error" *ngIf="f.contactId.invalid && submitted">
                                                        <span *ngIf="f.contactId.errors?.required">
                                                            {{
                                                            _AddAppointmentScreen.contactId .required
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-6 mb-3" *ngIf="hideContactField">
                                                    <label class="form-label">Contact Number
                                                        <span *ngIf="contactNumberFlag">*</span>
                                                    </label>
                                                    <input
                                                        [class.errorInput]="(f.contactNumber.errors?.mask && submitted)"
                                                        mask="(000) 000-0000" formControlName="contactNumber"
                                                        class="form-control rd-con" readonly
                                                        placeholder="(###) ###-####" />
                                                    <div class="display-error"
                                                        *ngIf="f.contactNumber.invalid && submitted">
                                                        <span *ngIf="f.contactNumber.errors?.mask">
                                                            {{ _AddAppointmentScreen.contactNumber.mask }}
                                                        </span>
                                                    </div>

                                                    <div class="display-error"
                                                        *ngIf="f.contactNumber.invalid && submitted">
                                                        <span *ngIf="f.contactNumber.errors?.required">
                                                            {{ _AddAppointmentScreen.contactNumber.required }}
                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-3">
                                                    <label class="form-label">Start Time <span>*</span></label>
                                                    <kendo-timepicker formControlName="startTime" placeholder="HH:MM"
                                                        format="hh:mm a">
                                                    </kendo-timepicker>
                                                    <div class="display-error" *ngIf="f.startTime.invalid && submitted">
                                                        <span *ngIf="f.startTime.errors?.required">
                                                            {{ _AddAppointmentScreen.startTime.required }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-3">
                                                    <label class="form-label">End Time <span>*</span></label>
                                                    <kendo-timepicker formControlName="endTime" placeholder="HH:MM"
                                                        format="hh:mm a">
                                                    </kendo-timepicker>
                                                    <div class="display-error" *ngIf="f.endTime.invalid && submitted">
                                                        <span *ngIf="f.endTime.errors?.required">
                                                            {{ _AddAppointmentScreen.endTime.required }}
                                                        </span>
                                                    </div>
                                                    <div class="display-error" *ngIf="invalidtime && submitted">
                                                        <span *ngIf="invalidtime && submitted">
                                                            {{ _AddAppointmentScreen.endTime.maxTime }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-6 mb-3" *ngIf="disableJobFairFlag">
                                                    <label class="form-label">Opportunity Size
                                                        <span *ngIf="isProspectRequired">*</span>
                                                    </label>
                                                    <div class="pos-re">
                                                        <span class="dollor">$</span>
                                                        <input formControlName="opportunitySize"
                                                            class="form-control error dollor-input"
                                                            placeholder="Opportunity Size"
                                                            (keypress)="ValidateKey.integer($event, 16)"
                                                            (focusout)="addCurrencyFormat($event, f.opportunitySize)"
                                                            maxlength="17"
                                                            (focus)="removeCurrencyFormat($event,f.opportunitySize)"
                                                            (focusout)="removeSpaces($event, f.opportunitySize)" />
                                                    </div>
                                                    <div class="display-error"
                                                        *ngIf="f.opportunitySize.invalid && submitted">
                                                        <span *ngIf="f.opportunitySize.errors?.required">
                                                            {{ _AddAppointmentScreen.opportunitySize.required }}
                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-3 date-control">
                                                    <label for="dateofAppointment" class="form-label">Date of
                                                        Appointment
                                                        <span>*</span></label>
                                                    <p-calendar [readonlyInput]="true" class="form-control"
                                                        placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                        inputId="dateofAppointment" [minDate]="minimumDate"
                                                        [ngClass]="{error:f.dateofAppointment.invalid && submitted}"
                                                        formControlName="dateofAppointment"></p-calendar>
                                                    <div class="display-error"
                                                        *ngIf="f.dateofAppointment.invalid && submitted">
                                                        <span *ngIf="f.dateofAppointment.errors?.required">
                                                            {{ _AddAppointmentScreen.dateofAppointment.required }}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                    <label class="form-label">Notes</label>
                                                    <textarea class="form-control" name="notes" formControlName="notes"
                                                        placeholder="Add a Note"
                                                        (focusout)="removeSpaces($event,f.notes)"></textarea>
                                                    <div class="display-error" *ngIf="f.notes.invalid && submitted">
                                                        <span *ngIf="f.notes.errors?.maxlength">
                                                            {{_AddAppointmentScreen.note.maxlength}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="blk-bt">
                    <button type="button" class="btn btn-outline-primary ms-2 me-4" (click)="cancel()">Cancel</button>
                    <button class="btn btn-primary " (click)="saveAppointment()">Save</button>
                </div>
                <!-- Dialog for confirmation -->
                <p-dialog [header]="isCancel ? _confirmBox.header : _confirmBox.header2" [modal]="true"
                    [(visible)]="_confirmBox.isVisible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }"
                    [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                    <p>{{ _confirmBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                        <ng-container *ngIf="isCancel">
                            <p-button (click)="_confirmBox.isVisible = false" label="No"
                                styleClass="btn btn-outline-primary">
                            </p-button>
                            <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
                        </ng-container>

                        <ng-container *ngIf="isAssignSelectEventValidorNot">
                            <p-button (click)="_confirmBox.isVisible = false" label="OK"
                                styleClass="btn btn-primary"></p-button>
                        </ng-container>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
